footer {
  margin-top: 89px;
  color: #fdfaff;
  background-image: url("../../media/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  h2 {
    font-size: 70px;
    color: #c1c7ff;
    font-weight: 400;
  }
  .footer-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2.5rem 2rem 0rem 2rem;
    gap: 65px 0;
    @media (max-width: 1024px) {
      gap: 15px 0;
    }
    .footer-site {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      gap: 0 20px;
      flex-wrap: wrap;
      @media (max-width: 1024px) {
        flex-direction: column;
        gap: 0 15px;
      }
      .footer-site-map {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: calc((100% / 4));
        text-align: start;
        @media (max-width: 1024px) {
          width: 100%;
        }
        p {
          padding: 6px 0;
          margin: 0;

          border-bottom: 1px solid #fdfaff;
          width: 100%;
        }
        ol {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
          margin: 0;
          margin-top: 4px;
          width: 100%;
        }
        .footer-links {
          //     counter-reset: list;
          a {
            text-decoration: none;
            color: inherit;
          }

          li {
            position: relative;
            margin-bottom: 10px;
            list-style: none;
            width: calc(100%);
          }
          //     li::before {
          //       content: counter(list) " ";
          //       counter-increment: list;
          //       position: absolute;
          //       padding: 4px 0;
          //     }

          .footer-item {
            display: flex;
            flex-direction: column;
            gap: 15px;
            height: 32px;
            padding: 0;
            overflow: hidden;
            .inner-link {
              padding: 4px 0;
              border-bottom: 1px solid #1a1b24;
              transition: all 0.25s ease-out;
              .num-link {
                margin-right: 40px;
              }
            }
            .outer-link {
              color: #001827;
              background-color: #95d1ff;
              display: flex;
              padding: 4px 0;
              align-items: center;
              padding-left: 25px;
              gap: 0 65px;
              transition: all 0.25s ease-out;
            }
          }
          .footer-item:hover {
            .inner-link {
              transform: translateY(160%);
            }
            .outer-link {
              transform: translateY(-160%);
            }
          }
        }
      }

      .footer-site-button {
        width: 15%;
        @media (max-width: 1024px) {
          width: 100%;
        }
      }
      button {
        @media (max-width: 1024px) {
          width: 100%;
          justify-content: space-between;
        }
        display: flex;
        padding: 9px;
        color: #001827;
        position: relative;
        overflow: hidden;
        font-family: "jost", sans-serif;
        font-weight: 500;
        height: 105px;
        background-color: #95d1ff;
        border: none;
        font-size: 16px;
        cursor: pointer;
        border-radius: 9px;
        div {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
          gap: 25px;

          .primary-arrow {
            transition: all 0.25s ease-out;
            transform: rotate(270deg);
          }
          .secondary-arrow {
            transition: all 0.25s ease-out;
            transform: translate3d(0, 0, 0) rotate(270deg);
          }
        }
      }
      button:hover {
        div {
          overflow: hidden;
          transition: all 0.25s ease-out;
          .primary-arrow {
            transform: translate3d(0, -250%, 0) rotate(270deg);
          }
          .secondary-arrow {
            transform: translate3d(0, -160%, 0) rotate(270deg);
          }
        }
      }
    }
    .footer-bottom {
      border-top: 1px solid #1a1b24;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px 0;
      font-size: 14px;
      @media (max-width: 1024px) {
        flex-direction: column;
        align-items: start;
      }
      div {
        @media (max-width: 1024px) {
          padding: 0.5rem 0;
        }
      }
      .email-media {
        display: flex;
        flex-direction: column;
        text-align: justify;
        a {
          color: #95d1ff;
        }
      }

      span {
        color: #ffbab1;
      }
    }
  }
}
