nav {
  position: sticky;
  top: 0;
  background-color: #fbf8ff;
  z-index: 10;
  padding: 0 32px;
}
.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 15px 0;
  }
  .logo {
    display: flex;
    width: 35%;
    gap: 15px;
    .logo-desc {
      font-size: 12px;
      color: #1a1b24;
      width: 50%;
      text-align: start;
      @media (max-width: 768px) {
        display: none;
      }
    }
  }
  a {
    text-decoration: none;
  }
  ul {
    width: 55%;
    justify-content: space-between;
    .nav-menu {
      display: flex;
      gap: 6px;
      @media (max-width: 1024px) {
        flex-direction: column;
      }
    }
  }

  .nav-links {
    list-style: none;
    display: flex;
    gap: 6px;
    padding: 0;
    li {
      height: 35px;
      overflow: hidden;
      .nav-item {
        display: flex;
        flex-direction: column;
        font-size: 16px;
        div {
          padding: 6px 9px;
          border-radius: 6px;
          background: transparent;
          transition: all 0.25s ease-out;
        }
        .inner-item {
          color: #12131c;
        }
        .outer-item {
          color: #ffffff;
          background-color: #004769;
          transform: translateY(-0.5%);
        }
      }
      .nav-item:hover {
        .inner-item {
          transform: translateY(-100%);
        }
        .outer-item {
          transform: translateY(-100%);
        }
      }
      .nav-item-active {
        .inner-item {
          transform: translateY(-100%);
        }
        .outer-item {
          transform: translateY(-100%);
        }
      }
    }
    @media (max-width: 1024px) {
      flex-direction: column;
      position: absolute;
      top: 50px;
      left: 0;
      background-color: #f9f9f9;
      width: 100%;
      height: 100vh;
      text-align: center;
      padding-top: 20px;
      transition: all 0.3s ease-in;
      transform: translateX(-100%);

      opaacity: 0;
    }
  }
  .menu-icon {
    display: none;
    i {
      font-style: normal;
    }
    @media (max-width: 1024px) {
      background-color: #004769;
      display: flex;
      align-items: center;
      color: white;
      border-radius: 6px;
      font-family: "jost", sans-serif;
      padding: 6px 9px;
      border: none;
    }
  }
}

.contact-btn {
  display: flex;
  flex-direction: column;
  height: 35px;
  overflow: hidden;
  align-self: center;
  button {
    display: flex;
    border: none;
    border-radius: 6px;
    font-family: "jost", sans-serif;
    font-size: 16px;
    padding: 6px 9px;
    cursor: pointer;
    gap: 6px;
    justify-content: center;

    transition: all 0.25s ease-out;
  }
  .inner-btn {
    background-color: transparent;
  }
  .outer-btn {
    color: #ffffff;
    background-color: #004769;
    transform: translateY(-0.5%);
  }
}

.contact-btn:hover {
  .inner-btn {
    transform: translateY(-100%);
  }
  .outer-btn {
    transform: translateY(-100%);
  }
}

@media (max-width: 1024px) {
  .nav-links.active {
    transform: translateX(0%);
    opacity: 1;
  }
}
