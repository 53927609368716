.running-text-appdev {
  width: 100vw;
  .header-running {
    background-color: #007cb3;
    color: #fbf8ff;
    margin-bottom: 4px;
    div {
      display: flex;
      align-items: center;
    }
    h2 {
      font-size: 70px;
      font-weight: 400;
      margin: 0 25px;
    }
    .arrow-headline {
      transform: rotate(45deg);
      margin-right: 25px;
    }
  }
  .service-running {
    border: 1px solid #1a1b24;
    border-radius: 9px;
    padding: 9px 25px;
    margin-right: 6px;
    h4 {
      margin: 0;
      font-size: 40px;
      color: #1a1b24;
      font-weight: 400;
    }
  }
}
