.sosmedcont {
  display: flex;
  justify-content: center;
  flex-direction: column;
  background-color: #12131c;
  .center {
    padding: 0 8.5rem;
    @media (max-width: 1024px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
    @media (min-width: 1300px) {
      padding: 0 18.5rem;
    }
    .herocenterhead {
      display: flex;
      color: #1a1b24;
      margin-bottom: 65px;
      font-size: 14px;
      width: 100%;
      .lefthead {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        .wrapper {
          width: 30%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
      .righthead {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .wrapper {
          width: 30%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
    .webdevtitle {
      font-family: "Jost", sans-serif;
      font-size: 56px;
      line-height: 86px;
      color: #c1c7ff;
      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    .webdevsub {
      color: #fdfaff;
      font-family: "Jost", sans-serif;
      font-size: 19px;
      line-height: 26px;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 23px;
      }
    }
    .pricebox {
      background-color: #b7e27f;
      border-radius: 16px;
      border: 1px solid rgba(26, 27, 36, 0.15);
      display: flex;

      align-items: center;
      gap: 15px;
      padding: 21px 12px;
      margin-top: 25px;

      .boxdes {
        width: calc(100% / 3);
        color: #000000;
        .boxtitle {
          font-family: "Jost", sans-serif;
          font-size: 23px;
          line-height: 33px;
          color: #000000;
        }
        .boxsub {
          font-family: "Jost", sans-serif;
          font-size: 16px;
          line-height: 20px;
          color: #000000;
        }
      }
      .boxpoint {
        font-family: "Jost", sans-serif;
        padding: 0 33px;
        width: calc(100% / 3);
        height: 80%;
        border-left: 2px solid #000000;
        border-right: 2px solid #000000;
        color: #000000;
        .pointtitle {
          margin-bottom: 17px;
        }
      }
      .boxprice {
        width: calc(100% / 3);
        padding: 0 30px;

        .pricetitle {
          font-family: "Jost", sans-serif;
          font-size: 12px;
          margin-bottom: 15px;
          color: #000000;
        }
        .price {
          font-family: "Jost", sans-serif;
          font-size: 14px;
          color: #000000;
          span {
            font-family: "Jost", sans-serif;
            font-size: 40px;
            line-height: 20px;
            color: #000000;
          }
        }
        .pricebutton {
          margin-top: 35px;
          .buttonwa {
            color: #ffffff;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 9px;
            background-color: #004769;
            width: 100%;
            height: 40px;
            font-size: 15px;
            font-weight: 500;
            font-family: "Jost", sans-serif;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .pricebox {
        flex-wrap: wrap;
        .boxdes {
          width: 100%;
        }
        .boxpoint {
          width: 30%;
          border-left: none;
          padding: 0 0px;
        }
        .boxprice {
          width: 67%;
          padding: 0;
        }
      }
    }
    @media (max-width: 768px) {
      .pricebox {
        flex-direction: column;
        .boxdes {
          width: 100%;
        }
        .boxpoint {
          width: 100%;
          border: none;
          padding: 0 0px;
        }
        .boxprice {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  .head-sosmed {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 40px;
    margin-top: 50px;
    @media (max-width: 1024px) {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }
    @media (max-width: 768px) {
      margin-top: 25px;
      padding: 0px 1.5rem;
      flex-direction: column;
      align-items: unset;
    }
    @media (min-width: 1300px) {
      padding: 0 18.5rem;
    }
    @media (min-width: 1025px) {
      padding: 0 8.5rem;
      margin-top: 25px;
    }
    .headleft {
      order: 2;
      @media (max-width: 768px) {
        order: 1;
      }
      .label {
        display: flex;
        justify-content: center;
        gap: 0 9px;
        background-color: rgba(116, 135, 255, 0.15);
        color: #fdfaff;
        width: fit-content;
        padding: 6px 9px;
        border-radius: 6px;
        font-size: 16px;
        line-height: 23.12px;
      }
    }
    .headright {
      order: 1;
      @media (max-width: 768px) {
        order: 2;
      }
      .title {
        font-size: 40px;
        line-height: 57.8px;
        font-weight: 400;
        color: #c1c7ff;
        margin: 0px 0 0px 0;
      }
    }
  }
  .showcase-content {
    width: 100%;

    .showcase-content-head {
      display: flex;
      color: #ffffff;
      font-size: 19px;
      margin-bottom: 40px;
      justify-content: space-between;
      gap: 40px;
      @media (max-width: 1024px) {
        padding: 0 1.5rem;
      }
      @media (min-width: 1280px) {
        padding: 0 8.5rem;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: unset;
        gap: 0px;
      }
      @media (min-width: 1300px) {
        padding: 0 18.5rem;
      }
      .showcase-content-head-title {
        color: white;
        margin-top: 15px;
      }
      .showcase-content-arrow {
        margin-top: 50px;
        margin-right: 50px;
        display: flex;
        gap: 0 15px;
        @media (max-width: 768px) {
          margin-top: 15px;
          justify-content: flex-end;
          width: 100%;
        }

        .left-arrow {
          display: flex;
          position: relative;
          rotate: 180deg;
          border: 1px solid white;
          border-radius: 4px;
          width: 27px;
          height: 24px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;
          .inner-left-arrow {
            position: absolute;
            transition: all 0.25s ease-out;
            left: 5px;
          }
          .outer-left-arrow {
            position: absolute;
            right: 30px;
            transition: all 0.25s ease-out;
          }
        }
        .left-arrow:hover {
          .inner-left-arrow {
            left: 30px;
          }
          .outer-left-arrow {
            right: 5px;
          }
        }
        .right-arrow {
          display: flex;
          position: relative;
          border: 1px solid white;
          border-radius: 4px;
          width: 27px;
          height: 24px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;
          .inner-right-arrow {
            position: absolute;
            transition: all 0.25s ease-out;
            left: 5px;
          }
          .outer-right-arrow {
            position: absolute;
            right: 30px;
            transition: all 0.25s ease-out;
          }
        }
        .right-arrow:hover {
          .inner-right-arrow {
            left: 30px;
          }
          .outer-right-arrow {
            right: 5px;
          }
        }
      }
    }

    .slider {
      width: 100%;
      .slick-slide {
        > div {
          // automatically generated 'div' by 'react-slick'
          margin-inline: 15px; //  space between slides / 2
        }
      }
      .slick-track {
      }
      .card {
        display: flex;
        border-radius: 12px;
        color: #ffffff;
        min-height: 524px;
        font-size: 12px;
        align-items: center;

        .card-top {
          display: flex;
          height: 68px;
          align-items: center;
          justify-content: center;
          gap: 6px;
          div > li {
            list-style-type: none;
            text-align: start;
          }
          li:nth-child(2) {
            opacity: 0.65;
          }

          .image-top-physical {
            width: 182px;
          }
          .image-top-digital {
            width: 38px;
          }
        }
        .card-body {
          overflow: hidden;
          border-top: 3px dashed #ffffff;
          border-bottom: 3px dashed #ffffff;
          height: 388px;
          .image-body-physical {
            width: 255px;
            height: 348px;
            object-fit: cover;
            margin: 20px auto;
            border-radius: 16px;
          }
          .image-body-digital {
            width: 100%;
            margin: 0 auto;
          }
          .image-body-logo {
            width: 122px;
          }
          .info-view {
            display: flex;
            gap: 0 12px;
            align-items: center;
            justify-content: space-between;
            margin: 20px 0;
            padding: 0 20px;
            li {
              list-style-type: none;
            }
            li:nth-child(1) {
              font-size: 23px;
            }
          }
        }
        .card-bottom {
          display: flex;
          height: 68px;
          justify-content: space-between;
          align-items: center;
          padding: 0 20px;
          li {
            list-style: none;
          }
          .card-info-physical-1 {
            text-align: start;
          }
          .card-info-physical-2 {
            text-align: right;
          }
          .card-info-digital {
            display: flex;
            flex-direction: column;
            gap: 2px 0;
          }
        }
      }
      .card-digital {
        background-color: #3b4999;
      }
      .card-physical {
        background-color: #e3136c;
      }
    }
  }
  .pricebutton {
    width: 100%;
    margin-bottom: 35px;
    margin-top: 35px;
    display: flex;
    justify-content: center;
    .buttonwa {
      color: #001827;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      border-radius: 9px;
      background-color: #95d1ff;
      padding: 0 34px;
      height: 40px;
      font-size: 15px;
      font-weight: 500;
      font-family: "Jost", sans-serif;
    }
  }
}
