.testimoni-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .label {
    display: flex;
    justify-content: center;
    gap: 0 9px;
    background-color: #314ef526;
    color: #1a1b24;
    height: auto;
    padding: 6px 9px;
    border-radius: 6px;
    font-size: 16px;
    line-height: 23.12px;
    font-weight: 400;
  }
  .title {
    font-size: 40px;
    line-height: 57.8px;
    font-weight: 400;
    color: #0027c0;
    margin: 9px 0 15px 0;
  }
  .slider {
    height: 370px;
    width: 100%;
    display: flex;
    .slider1 {
      margin-left: -20%;
      width: 40%;
      background-color: #efeeff;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      .sliderspacing {
        width: 95%;
        height: 90%;
      }
    }
    .arrowspace {
      width: 5%;
      display: flex;
      align-items: center;
      justify-content: center;
      .left-arrow {
        display: flex;
        position: relative;
        rotate: 180deg;
        border: 1px solid #1a1b24;
        border-radius: 4px;
        width: 27px;
        height: 24px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        .inner-left-arrow {
          position: absolute;
          transition: all 0.25s ease-out;
          left: 5px;
        }
        .outer-left-arrow {
          position: absolute;
          right: 30px;
          transition: all 0.25s ease-out;
        }
      }
      .left-arrow:hover {
        .inner-left-arrow {
          left: 30px;
        }
        .outer-left-arrow {
          right: 5px;
        }
      }
      .right-arrow {
        display: flex;
        position: relative;
        border: 1px solid #1a1b24;
        border-radius: 4px;
        width: 27px;
        height: 24px;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        cursor: pointer;
        .inner-right-arrow {
          position: absolute;
          transition: all 0.25s ease-out;
          left: 5px;
        }
        .outer-right-arrow {
          position: absolute;
          right: 30px;
          transition: all 0.25s ease-out;
        }
      }
      .right-arrow:hover {
        .inner-right-arrow {
          left: 30px;
        }
        .outer-right-arrow {
          right: 5px;
        }
      }
    }

    .slider2 {
      width: 50%;
      background-color: #efeeff;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      .sliderspacing {
        width: 95%;
        height: 90%;

        .slidertext {
          height: 100%;
          .testimoni {
            height: 50%;
            font-size: 32px;
          }
          .footertestimoni {
            height: 50%;
            display: flex;
            align-items: end;
            .person {
              .personname {
                font-size: 22px;
              }
              .persontitle {
                opacity: 40%;
                color: #1a1b24;
              }
            }
          }
        }
      }
    }

    .slider3 {
      margin-right: -20%;
      width: 40%;
      background-color: #efeeff;
      border-radius: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      .sliderspacing {
        width: 95%;
        height: 90%;
      }
    }
  }
}
