.aboutcontainer {
  display: flex;
  justify-content: center;
  padding: 6.7rem 8.5rem;
  @media (max-width: 1024px) {
    padding: 6.7rem 1.5rem;
  }
  @media (min-width: 1300px) {
    margin-top: 9.5rem;
    padding: 0 18.5rem;
    margin-bottom: 9.5rem;
  }
  .center {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (min-width: 768px) {
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 90%;
    }
    .abouttitle {
      font-family: "Jost", sans-serif;
      color: #0027c0;
      font-size: 70px;
      @media (max-width: 768px) {
        width: 100%;
        font-size: 40px;
      }
    }
    .aboutdes {
      font-family: "Jost", sans-serif;

      font-size: 19px;
      br {
        display: block; /* makes it have a width */
        content: ""; /* clears default height */
        margin-top: 10px; /* change this to whatever height you want it */
      }
    }
    .aboutportof {
      display: flex;
      justify-content: center;
      width: 150%;
      padding: 30px 0;
      @media (max-width: 768px) {
        width: 120%;
      }
    }
  }
}
