.appdevcont {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .center {
    margin-top: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    @media (max-width: 1024px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
    @media (min-width: 1300px) {
      padding: 0 18.5rem;
    }
    @media (min-width: 1024px) {
      padding: 0 10.5rem;
    }
    .herocenterhead {
      display: flex;
      color: #1a1b24;
      margin-bottom: 65px;
      font-size: 14px;
      width: 100%;
      .lefthead {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        .wrapper {
          width: 30%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
      .righthead {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .wrapper {
          width: 30%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
    .webdevtitle {
      text-align: center;
      font-family: "Jost", sans-serif;
      font-size: 56px;
      line-height: 86px;
      color: #0027c0;
      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
        text-align: left;
        width: 100%;
      }
      @media (max-width: 768px) {
        text-align: left;
      }
    }
    .webdevsub {
      width: 90%;
      text-align: center;
      color: #1a1b24;
      font-family: "Jost", sans-serif;
      font-size: 19px;
      line-height: 26px;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 23px;
        text-align: left;
        width: 100%;
      }
      @media (max-width: 768px) {
        text-align: left;
        width: 100%;
      }
    }
    .buttonslider {
      display: flex;
      gap: 15px;
      width: 100%;
      .buttoncontainer {
        width: 100%;

        .garisbutton {
          width: 100%;
          border: none;
          height: 5px;
          border-radius: 6px;
          cursor: pointer;
          transition: background-image 0.3s ease;
          background-color: #5c5e6f;
          &:hover {
            background-image: url(../../media/buttongradient.png);
          }

          &.active {
            background-position: 25% 75%;
            background-image: url(../../media/buttongradient.png);
          }
        }
        .buttonapp {
          margin-top: 30px;
          font-family: "Jost", sans-serif;
          font-size: 19px;
          font-weight: 400;
          color: white;
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          border: none;
          border-radius: 6px;
          background-color: #5c5e6f;
          transition: background-image 0.3s ease;
          @media (max-width: 768px) {
            display: none;
          }
          &:hover {
            background-image: url(../../media/buttongradient.png);
          }
          &.active {
            background-position: 25% 75%;
            background-image: url(../../media/buttongradient.png);
          }
        }
        .buttontext {
          margin-top: 15px;
          font-family: "Jost", sans-serif;
          font-size: 18px;
          font-weight: 400;
          @media (max-width: 768px) {
            display: none;
          }
        }
      }
    }
  }
  .slidercenter {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .sliderapp {
      margin-top: 25px;
      width: 75%;
      @media (min-width: 1024px) {
        width: 75%;
      }
      @media (max-width: 1024px) {
        width: 98%;
      }
      .slick-slide {
        > div {
          // automatically generated 'div' by 'react-slick'
          margin-inline: 15px; //  space between slides / 2
        }
      }
      .slick-slider {
        .slick-list {
          overflow: visible;
        }
      }
      .slideritem {
        display: flex;
        flex-direction: row;
        border-radius: 40px;
        gap: 25px;
        width: 100%;
        height: 410px;
        z-index: 1;
        background-image: url(../../media/buttongradient.png);
        @media (max-width: 768px) {
          flex-direction: column;
          border-radius: 16px;
          margin-top: 10px;
        }
        .slidercontent {
          width: 50%;
          margin: 2.5rem 0 2.5rem 3.5rem;
          @media (max-width: 768px) {
            width: 100%;
            margin: 0;
          }
          .title {
            font-family: "Jost", sans-serif;
            font-size: 40px;
            line-height: 57.8px;
            font-weight: 400;
            color: #c1c7ff;
            @media (max-width: 768px) {
              font-size: 28px;
              line-height: 40.46px;
              margin: 1rem 1.5rem 0 1rem;
            }
          }
          .subtitle {
            margin: 15px 0 15px 0;
            font-family: "Jost", sans-serif;
            font-size: 19px;
            line-height: 27.46px;
            font-weight: 400;
            color: #fdfaff;
            @media (max-width: 768px) {
              font-size: 16px;
              line-height: 23.12px;
              margin: 0rem 1.5rem 0 1rem;
            }
          }
          .buttonwa {
            margin-top: 15px;
            color: #001827;
            border: none;
            text-align: left;
            border-radius: 9px;
            background-color: #95d1ff;
            padding: 0 34px;
            height: 40px;
            font-size: 15px;
            font-weight: 500;
            font-family: "Jost", sans-serif;
            @media (max-width: 768px) {
              margin: 1rem 1.5rem 0 1rem;
            }
          }
        }
        .sliderimage {
          margin: 2.5rem 3.5rem 2.5rem 0;
          width: 50%;
          background-color: red;
          z-index: 5;
          @media (max-width: 768px) {
            width: 100%;
            margin: 0;
            height: 100%;
          }
        }
      }
      .mobilebutton {
        @media (min-width: 768px) {
          display: none;
        }
        padding-bottom: 20px;
        .buttonapp {
          margin-top: 0px;
          font-family: "Jost", sans-serif;
          font-size: 19px;
          font-weight: 400;
          color: white;
          display: flex;
          align-items: center;
          padding: 10px;
          cursor: pointer;
          border: none;
          border-radius: 6px;
          background-color: #5c5e6f;
          transition: background-image 0.3s ease;
          &:hover {
            background-image: url(../../media/buttongradient.png);
          }
          &.active {
            background-position: 25% 75%;
            background-image: url(../../media/buttongradient.png);
          }
        }
        .buttontext {
          height: 130px;
          margin-top: 15px;
          font-family: "Jost", sans-serif;
          font-size: 18px;
          font-weight: 400;
        }
      }
    }
  }
}
