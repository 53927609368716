.webdevcont {
  display: flex;
  justify-content: center;
  flex-direction: column;

  .center {
    padding: 0 8.5rem;
    @media (max-width: 1024px) {
      padding: 0 1.5rem;
    }
    @media (max-width: 768px) {
      padding: 0 1.5rem;
    }
    @media (min-width: 1300px) {
      padding: 0 18.5rem;
    }
    .herocenterhead {
      display: flex;
      color: #1a1b24;
      margin-top: 105px;
      margin-bottom: 65px;
      font-size: 14px;
      width: 100%;
      .lefthead {
        width: 50%;
        display: flex;
        justify-content: flex-start;
        .wrapper {
          width: 30%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
      .righthead {
        width: 50%;
        display: flex;
        justify-content: flex-end;
        .wrapper {
          width: 30%;
          display: flex;
          justify-content: space-between;
          @media (max-width: 768px) {
            width: 90%;
          }
        }
      }
    }
    .webdevtitle {
      font-family: "Jost", sans-serif;
      font-size: 56px;
      line-height: 86px;
      color: #0027c0;
      @media (max-width: 1024px) {
        font-size: 32px;
        line-height: 40px;
      }
    }
    .webdevsub {
      color: #1a1b24;
      font-family: "Jost", sans-serif;
      font-size: 19px;
      line-height: 26px;
      @media (max-width: 1024px) {
        font-size: 16px;
        line-height: 23px;
      }
    }
    .pricebox {
      background-color: #da342e;
      border-radius: 16px;
      border: 1px solid rgba(26, 27, 36, 0.15);
      display: flex;

      align-items: center;
      gap: 15px;
      padding: 21px 12px;
      margin-top: 25px;

      .boxdes {
        width: calc(100% / 3);
        color: white;
        .boxtitle {
          font-family: "Jost", sans-serif;
          font-size: 23px;
          line-height: 33px;
          color: white;
        }
        .boxsub {
          font-family: "Jost", sans-serif;
          font-size: 16px;
          line-height: 20px;
          color: white;
        }
      }
      .boxpoint {
        font-family: "Jost", sans-serif;
        padding: 0 33px;
        width: calc(100% / 3);
        height: 80%;
        border-left: 2px solid white;
        border-right: 2px solid white;
        color: white;
        .pointtitle {
          margin-bottom: 17px;
        }
      }
      .boxprice {
        width: calc(100% / 3);
        padding: 0 30px;

        .pricetitle {
          font-family: "Jost", sans-serif;
          font-size: 12px;
          margin-bottom: 15px;
          color: white;
        }
        .price {
          font-family: "Jost", sans-serif;
          font-size: 14px;
          color: white;
          span {
            font-family: "Jost", sans-serif;
            font-size: 40px;
            line-height: 20px;
            color: white;
          }
        }
        .pricebutton {
          margin-top: 35px;
          .buttonwa {
            color: #001827;
            display: flex;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 9px;
            background-color: #95d1ff;
            width: 100%;
            height: 40px;
            font-size: 15px;
            font-weight: 500;
            font-family: "Jost", sans-serif;
          }
        }
      }
    }

    @media (max-width: 1024px) {
      .pricebox {
        flex-wrap: wrap;
        .boxdes {
          width: 100%;
        }
        .boxpoint {
          width: 30%;
          border-left: none;
          padding: 0 0px;
        }
        .boxprice {
          width: 67%;
          padding: 0;
        }
      }
    }
    @media (max-width: 768px) {
      .pricebox {
        flex-direction: column;
        .boxdes {
          width: 100%;
        }
        .boxpoint {
          width: 100%;
          border: none;
          padding: 0 0px;
        }
        .boxprice {
          width: 100%;
          padding: 0;
        }
      }
    }
  }
  .head-webdev {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding-top: 40px;
    margin-top: 50px;
    @media (max-width: 1024px) {
      padding: 1.5rem 1.5rem 0 1.5rem;
    }
    @media (max-width: 768px) {
      margin-top: 25px;
      padding: 0px 1.5rem;
      flex-direction: column;
      align-items: unset;
    }
    @media (min-width: 1300px) {
      padding: 0 18.5rem;
    }
    @media (min-width: 1025px) {
      padding: 0 8.5rem;
    }
    .headleft {
      order: 2;
      @media (max-width: 768px) {
        order: 1;
      }
      .label {
        display: flex;
        justify-content: center;
        gap: 0 9px;
        background-color: #002ede26;
        color: #1a1b24;
        width: fit-content;
        padding: 6px 9px;
        border-radius: 6px;
        font-size: 16px;
        line-height: 23.12px;
      }
    }
    .headright {
      order: 1;
      @media (max-width: 768px) {
        order: 2;
      }
      .title {
        font-size: 40px;
        line-height: 57.8px;
        font-weight: 400;
        color: #0027c0;
        margin: 0px 0 0px 0;
      }
    }
  }
  .showcase-content-webdev {
    width: 100%;
    margin-bottom: 105px;

    .showcase-content-head {
      display: flex;
      color: #ffffff;
      padding: 0 12.5rem;
      font-size: 19px;
      margin-bottom: 40px;
      justify-content: space-between;
      gap: 40px;
      @media (max-width: 1024px) {
        padding: 0 1.5rem;
      }
      @media (min-width: 1280px) {
        padding: 0 8.5rem;
      }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: unset;
        gap: 0px;
      }
      @media (min-width: 1300px) {
        padding: 0 18.5rem;
      }
      .showcase-content-head-title {
        color: #1a1b24;
        margin-top: 15px;
      }
      .showcase-content-arrow {
        margin-top: 50px;
        margin-right: 50px;
        display: flex;
        gap: 0 15px;
        @media (max-width: 768px) {
          margin-top: 15px;
          justify-content: flex-end;
          width: 100%;
        }

        .left-arrow {
          display: flex;
          position: relative;
          rotate: 180deg;
          border: 1px solid black;
          border-radius: 4px;
          width: 27px;
          height: 24px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;
          .inner-left-arrow {
            position: absolute;
            transition: all 0.25s ease-out;
            left: 5px;
          }
          .outer-left-arrow {
            position: absolute;
            right: 30px;
            transition: all 0.25s ease-out;
          }
        }
        .left-arrow:hover {
          .inner-left-arrow {
            left: 30px;
          }
          .outer-left-arrow {
            right: 5px;
          }
        }
        .right-arrow {
          display: flex;
          position: relative;
          border: 1px solid black;
          border-radius: 4px;
          width: 27px;
          height: 24px;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          cursor: pointer;
          .inner-right-arrow {
            position: absolute;
            transition: all 0.25s ease-out;
            left: 5px;
          }
          .outer-right-arrow {
            position: absolute;
            right: 30px;
            transition: all 0.25s ease-out;
          }
        }
        .right-arrow:hover {
          .inner-right-arrow {
            left: 30px;
          }
          .outer-right-arrow {
            right: 5px;
          }
        }
      }
    }
    .slider {
      display: flex;
      justify-content: space-between;
      gap: 15px;
      @media (max-width: 1024px) {
        padding: 0 1.5rem;
        gap: 0px;
      }
      div {
        padding: 0px;

        img {
          border-radius: 0;

          /* Reset inherited border-radius */
        }
      }
      .slider-item1 {
        width: 21%;
        height: fit-content;
        margin-left: -8%;
        @media (max-width: 1024px) {
          display: none;
        }
        .slick-slide {
          /* Sesuaikan tinggi slide */
          height: 182px;
        }
        div {
          border-radius: 0px 16px 16px 0px;
        }
      }
      .slider-item2 {
        width: 70%;

        position: relative;
        @media (max-width: 1024px) {
          width: 50%;
          margin-left: -5px;
        }
        @media (max-width: 768px) {
          width: 100%;
        }
        .slick-slide {
          text-align: center;
          color: white;
          height: 524px;
          @media (max-width: 1024px) {
            height: auto;
          }
        }
        div {
          border-radius: 16px;
          .background-img {
            position: relative;
            width: 120%;
            z-index: -20;
            object-fit: cover;
            transform: translateY(-200px);
            @media (max-width: 1024px) {
              width: 100%;
              transform: translateY(0px);
            }
          }
        }

        .slider-content {
          position: absolute;
          margin-right: 10px;
          border-radius: 0;
          top: 0;
          bottom: 0;
          margin: 20px;
          display: flex;
          flex-direction: column;
          width: 66.5vw;
          justify-content: space-between;
          align-items: start;
          overflow: hidden;
          z-index: 20;

          @media (max-width: 1024px) {
            display: flex;
            position: relative;
            width: 100%;
            margin: 0px;
          }
          .rating {
            transform: translateY(-100%);
            transition: all 0.25s ease-in-out;
            @media (max-width: 1024px) {
              transform: translateY(0);
              order: 2;
              width: 100%;
            }
          }
          .read-more {
            @media (max-width: 1024px) {
              display: none;
            }
            transform: translateY(100%);
            transition: all 0.25s ease-in-out;
          }
          .slider-content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: 1024px) {
              order: 1;
            }
            .slider-content-label {
              background: #fdfaff;
              color: #12131c;
              text-align: start;
              border-radius: 9px;
              padding: 9px;
              width: 50%;
              @media (max-width: 1024px) {
                width: 100%;
              }
              .title {
                color: #0027c0;
                font-size: 28px;
                line-height: 40px;
                margin: 0 0 6px 0;
              }
              .description {
                font-size: 14px;
                margin: 0 0 25px 0;
              }
              .tags {
                font-size: 12px;
                border: 1px solid #12131c;
                border-radius: 4px;
                width: fit-content;
                padding: 4px;
              }
            }
          }
        }
        .slider-content:hover {
          .rating {
            transform: translateY(0);
          }
          .read-more {
            transform: translateY(0);
          }
        }
      }
      .slider-item3 {
        width: 21%;
        margin-right: -8%;
        position: relative;
        @media (max-width: 768px) {
          display: none;
        }

        @media (max-width: 1024px) {
          width: 50%;
          margin-right: 0%;
          margin-left: 10px;
        }
        .background-img {
          position: relative;
          width: 100%;
          z-index: -20;
          object-fit: cover;
          object-position: center;
        }
        .slider-content {
          position: absolute;
          margin-right: 10px;
          border-radius: 0;
          top: 0;
          bottom: 0;
          margin: 20px;
          display: flex;
          flex-direction: column;
          width: 66.5vw;
          justify-content: space-between;
          align-items: start;
          overflow: hidden;
          z-index: 20;
          @media (min-width: 1024px) {
            display: none;
          }
          @media (max-width: 1024px) {
            display: flex;
            position: relative;
            width: 100%;
            margin: 0px;
          }
          .rating {
            transform: translateY(-100%);
            transition: all 0.25s ease-in-out;
            @media (max-width: 1024px) {
              transform: translateY(0);
              order: 2;
              width: 100%;
            }
          }
          .read-more {
            @media (max-width: 1024px) {
              display: none;
            }
            transform: translateY(100%);
            transition: all 0.25s ease-in-out;
          }
          .slider-content-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            @media (max-width: 1024px) {
              order: 1;
            }
            .slider-content-label {
              background: #fdfaff;
              color: #12131c;
              text-align: start;
              border-radius: 9px;
              padding: 9px;
              width: 50%;
              @media (max-width: 1024px) {
                width: 100%;
              }
              .title {
                color: #0027c0;
                font-size: 28px;
                line-height: 40px;
                margin: 0 0 6px 0;
              }
              .description {
                font-size: 14px;
                margin: 0 0 25px 0;
              }
              .tags {
                font-size: 12px;
                border: 1px solid #12131c;
                border-radius: 4px;
                width: fit-content;
                padding: 4px;
              }
            }
          }
        }
        .slider-content:hover {
          .rating {
            transform: translateY(0);
          }
          .read-more {
            transform: translateY(0);
          }
        }

        .slick-slide {
          /* Sesuaikan tinggi slide */
          height: 182px;
          @media (max-width: 1024px) {
            height: auto;
          }
        }
        div {
          border-radius: 16px 0 0 16px;
          @media (max-width: 1024px) {
            border-radius: 16px;
          }
        }
      }
    }
    button {
      display: flex;
      padding: 9px;
      color: #001827;
      position: relative;
      overflow: hidden;
      font-family: "jost", sans-serif;
      font-weight: 500;
      height: 105px;
      background-color: #95d1ff;
      border: none;
      font-size: 16px;
      cursor: pointer;
      border-radius: 9px;

      div {
        display: flex;
        flex-direction: column;
        margin-top: 50px;
        gap: 25px;
        transition: all 0.25s ease-out;

        .primary-arrow {
          transition: all 0.25s ease-out;
        }
        .secondary-arrow {
          transition: all 0.25s ease-out;
          transform: translate3d(-150%, 0, 0);
        }
      }
    }
    .btn-work {
      display: flex;
      justify-content: flex-end;
      margin-top: -110px;
      padding: 0 20px 0 0;
      @media (max-width: 1024px) {
        display: none;
      }
    }
    button:hover {
      div {
        overflow: hidden;
        .primary-arrow {
          transition: all 0.25s ease-out;
          transform: translate3d(200%, -250%, 0);
        }
        .secondary-arrow {
          transition: all 0.25s ease-out;
          transform: translate3d(0, -160%, 0);
        }
      }
    }
  }
}
